import React from "react";

function Loader() {
  return (
    <div style={{ height: "900px" }}>
      <div className="loader-main-wrap" style={{ display: "block" }}>
        <div className="loader-inner-wrap">
          <i className="fa fa-spinner fa-spin" aria-hidden="true" />
        </div>
      </div>
    </div>
  );
}

export default Loader;

import React, { useEffect, useState, memo } from "react";
import { Link, useNavigate } from "react-router-dom";
import Config from "../../config";
import Navbarjs from "../Scripts/navbarjs";
import PlanPopup from "./../../stripe/Stripeduepayment/StripeDuePayment";
import DuePayment from "../Pages/DuePayment/DuePayment";
import { createSlug } from "../Widgets/Slug/Slug";

const Navbar = () => {
  const navigate = useNavigate();

  const [services, setServices] = useState([]);
  const [legalNav, setLegalNav] = useState([]);
  // const [modalOpen1, setModalOpen1] = useState(false);
  // const [data, setData] = useState([]);

  const getCount = (id, type) => {
    let gId = ``;
    let uId = ``;
    let query = ``;
    if (type === "user") {
      uId = id;
      query = `/payment/cart-count?custId=${uId}`;
    } else if (type === "guest") {
      gId = id;
      query = `/payment/cart-count?guestId=${gId}`;
    }
    Config.get(query)
      .then((res) => {
        if (res.status) {
          // setCartItem(res.data);
          document.getElementById("product_in_cart").innerText = res.data;
          document.getElementById("product_in_cart_mob").innerText = res.data;
          localStorage.setItem("cartCount", res.data);
        }
      })
      .catch((err) => {
        console.log(err, "getCount");
      });
  };

  const serviceFun = (id) => {
    navigate(`/legal-service/${id}`);
  };

  const legalServiceData = () => {
    Config.get(`/user/legal-doc-category`)
      .then((res) => {
        if (res.status) {
          setLegalNav(res.data);
        }
      })
      .catch((err) => {
        console.log(err, "legalServiceData");
      });
  };

  const legalDocData = () => {
    Config.get(`/user/legal-services?limit=10`)
      .then((res) => {
        if (res.status) {
          setServices(res.data);
        }
      })
      .catch((err) => {
        console.log(err, "legalDocData");
      });
  };

  const changeView = () => {
    document.getElementById("navbar-mobile-style-1").style.display = "block";
  };

  const changeViewDispalyHide = () => {
    document.getElementById("navbar-mobile-style-1").style.display = "none";
  };

  useEffect(() => {
    legalServiceData();
    legalDocData();
    if ("cartCount" in localStorage) {
    } else {
      if (
        localStorage.getItem("user_id") !== "" &&
        localStorage.getItem("user_id") !== null &&
        localStorage.getItem("user_id") !== undefined
      ) {
        getCount(localStorage.getItem("user_id"), "user");
      } else if (
        localStorage.getItem("guestId") !== "" &&
        localStorage.getItem("guestId") !== null &&
        localStorage.getItem("guestId") !== undefined
      ) {
        getCount(localStorage.getItem("guestId"), "guest");
      }
    }
  }, []);

  const logout = () => {
    localStorage.clear();
    navigate("/signin");
  };

  const mylegalHub = () => {
    navigate("/signin");
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-top  bg-white">
        
        <div className="container">
          <Link className="navbar-brand d-lg-none" to="/">
            <img src="/assets/img/svg/logo.svg" alt="" />
          </Link>
          <ul className="mobile_cart">
            <li className="nav-item cart_counter_li for-cursor">
              {" "}
              <span
                className="cart_counter"
                id="product_in_cart"
                onClick={(e) => {
                  e.preventDefault();
                  if (
                    localStorage.getItem("user_id") !== null &&
                    localStorage.getItem("user_id") !== ""
                  )
                    navigate(
                      `/checkout-multiple-cart?_id=${localStorage.getItem(
                        "user_id"
                      )}`
                    );
                  else
                    navigate(
                      `/checkout-multiple-cart?_id=${localStorage.getItem(
                        "guestId"
                      )}`
                    );
                }}
              >
                {localStorage.getItem("cartCount") !== "" &&
                localStorage.getItem("cartCount") !== null
                  ? localStorage.getItem("cartCount")
                  : 0}
                {/* {cartItem} */}
              </span>
              <a
                className="nav-link for-cursor"
                onClick={(e) => {
                  e.preventDefault();
                  if (
                    localStorage.getItem("user_id") !== null &&
                    localStorage.getItem("user_id") !== ""
                  )
                    navigate(
                      `/checkout-multiple-cart?_id=${localStorage.getItem(
                        "user_id"
                      )}`
                    );
                  else
                    navigate(
                      `/checkout-multiple-cart?_id=${localStorage.getItem(
                        "guestId"
                      )}`
                    );
                }}
              >
                <span>
                  <img src="/assets/img/svg/cart.svg" alt="" />
                </span>
              </a>
            </li>
          </ul>
          <a
            onClick={() => {
              changeView();
            }}
            className="navbar-toggle order-4 popup-inline"
            href="#navbar-mobile-style-1"
          >
            <span />
            <span />
            <span />
          </a>
          <ul className="nav navbar-nav order-2 nav-no-opacity">
            <li className="nav-item navbar-dropdown">
              <a className="nav-link for-cursor">
                <span>
                  Legal Documents{" "}
                  <img src="/assets/img/svg/dropdown_veector.svg" alt="" />
                </span>
              </a>
              <div className="dropdown-menu shadow">
                <ul className="nav navbar-nav for-cursor">
                  {legalNav.map((service) => (
                    <li className="nav-item for-cursor" key={service._id}>
                      <a
                        className="nav-link for-cursor"
                        data-serviceid={service._id}
                        onClick={(e) => {
                          e.preventDefault();
                          const slug = createSlug(service.name);
                          navigate(
                            `/legal-document/${slug}`
                          );
                        }}
                      >
                        <span>{service.name}</span>
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </li>
            <li className="nav-item navbar-dropdown">
              <a className="nav-link for-cursor">
                <span>
                  Legal Services{" "}
                  <img src="/assets/img/svg/dropdown_veector.svg" alt="" />{" "}
                </span>
              </a>
              <div className="dropdown-menu shadow">
                <ul className="nav navbar-nav for-cursor">
                  {/* service */}
                  {services.map((service) => (
                    <li className="nav-item for-cursor" key={service._id}>
                      {service.name !='Immigration' && <a
                        className="nav-link for-cursor"
                        data-serviceid={service._id}
                        onClick={() => {
                          const slug = createSlug(service.name);
                          serviceFun(slug);
                        }} >
                        <span>{service.name}</span>
                      </a>}
                    </li> 
                  ))}
                </ul>
              </div>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/pricing">
                <span>Subscription Plans</span>
              </Link>
            </li>
          </ul>
          {/* Button*/}
          <ul className="nav navbar-nav order-2 ms-auto nav-no-opacity">
            <li className="nav-item navbar-dropdown d-flex align-items-center">
              <Link className="navbar-brand" to="/">
                <img src="/assets/img/svg/logo.svg" alt="" />
              </Link>
            </li>
          </ul>
          {/* Button*/}
          <ul className="nav navbar-nav order-2 ms-auto nav-no-opacity">
            <li className="nav-item ">
              <Link className="nav-link" to="/blogs">
                <span>Blog</span>
              </Link>
            </li>
            <li className="nav-item ">
              <Link className="nav-link" to="/meet-the-lawyer">
                <span>Meet the Lawyers</span>
              </Link>
            </li>
            <li className="nav-item cart_counter_li for-cursor">
              {" "}
              <span
                className="cart_counter"
                id="product_in_cart_mob"
                onClick={(e) => {
                  e.preventDefault();
                  if (
                    localStorage.getItem("user_id") !== null &&
                    localStorage.getItem("user_id") !== ""
                  )
                    navigate(
                      `/checkout-multiple-cart?_id=${localStorage.getItem(
                        "user_id"
                      )}`
                    );
                  else
                    navigate(
                      `/checkout-multiple-cart?_id=${localStorage.getItem(
                        "guestId"
                      )}`
                    );
                }}
              >
                {localStorage.getItem("cartCount") !== "" &&
                localStorage.getItem("cartCount") !== null
                  ? localStorage.getItem("cartCount")
                  : 0}
                {/* {cartItem} */}
              </span>
              <a
                className="nav-link for-cursor"
                onClick={(e) => {
                  e.preventDefault();
                  if (
                    localStorage.getItem("user_id") !== null &&
                    localStorage.getItem("user_id") !== ""
                  )
                    navigate(
                      `/checkout-multiple-cart?_id=${localStorage.getItem(
                        "user_id"
                      )}`
                    );
                  else
                    navigate(
                      `/checkout-multiple-cart?_id=${localStorage.getItem(
                        "guestId"
                      )}`
                    );
                }}
              >
                <span>
                  <img src="/assets/img/svg/cart.svg" alt="" />
                </span>
              </a>
            </li>
          </ul>
          {/* Button*/}

          {(localStorage.getItem("token") === "" ||
            localStorage.getItem("token") === null) &&
            (localStorage.getItem("user_id") === "" ||
              localStorage.getItem("user_id") === null) && (
              <div
                className="dropdown btn d-none d-sm-inline-flex btn btn-sm btn-accent-1 ms-auto me-30 me-lg-0 order-2 order-lg-3"
                onClick={mylegalHub}
              >
                <img src="/assets/img/svg/account.svg" alt="" />
                &nbsp; My Legal Hub
              </div>
            )}

          {localStorage.getItem("token") && localStorage.getItem("user_id") && (
            <div className="dropdown btn d-none d-sm-inline-flex btn btn-sm btn-accent-1 ms-auto me-30 me-lg-0 order-2 order-lg-3">
              <div className="dropdown-content">
                <a
                  href="/my-profile"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/my-profile");
                  }}
                  className="border_bottom_1"
                >
                  {" "}
                  <img
                    src="/assets/img/svg/profile_icon.svg"
                    alt=""
                    width="24px"
                  />
                  &nbsp; Profile
                </a>
                <a
                  href="/signin"
                  onClick={(e) => {
                    e.preventDefault();
                    logout();
                  }}
                >
                  <img
                    src="/assets/img/svg/logout_icon.svg"
                    width="24px"
                    alt=""
                  />
                  &nbsp; Logout
                </a>
              </div>
              <img src="/assets/img/svg/account.svg" alt="" />
              &nbsp; My Legal Hub
            </div>
          )}
        </div>
      </nav>

      {/* Navbar mobile*/}
      <div
        className="MobileNavBar navbar-mobile navbar-mobile-style-1 bg-white"
        id="navbar-mobile-style-1"
      >
        <div className="navbar-wrapper">
          <div className="navbar-head">
            <Link className="navbar-brand d-block d-md-none" to="/">
              {" "}
              <img src="/assets/img/svg/logo.svg" alt="" />
            </Link>
            <a
              onClick={() => {
                changeViewDispalyHide();
              }}
              className="navbar-toggle popup-modal-dismiss"
            >
              <span />
              <span />
              <span />
            </a>
          </div>
          <div className="navbar-body">
            <ul className="nav navbar-nav navbar-nav-collapse">
              <li className="nav-item navbar-collapse">
                <a
                  className="nav-link for-cursor"
                  href="#navbarCollapseHome"
                  data-bs-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="navbarCollapseHome"
                >
                  <span>
                    Legal Documents{" "}
                    <img src="/assets/img/svg/dropdown_veector.svg" alt="" />{" "}
                  </span>
                </a>
                <div
                  className="navbar-collapse-menu collapse"
                  id="navbarCollapseHome"
                >
                  <ul className="nav navbar-nav">
                    {legalNav.slice(0, 3).map((service) => (
                      <li
                        onClick={() => {
                          changeViewDispalyHide();
                        }}
                        className="nav-item for-cursor"
                        key={service._id}
                      >
                        <a
                          className="nav-link for-cursor"
                          data-serviceid={service._id}
                          onClick={(e) => {
                            e.preventDefault();
                            const slug = createSlug(service.name);
                            navigate(
                              `/legal-document/${slug}`
                            );
                          }}
                        >
                          <span>{service.name}</span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </li>
              <li className="nav-item navbar-collapse">
                <a
                  className="nav-link"
                  href="#navbarCollapseServices"
                  data-bs-toggle="collapse"
                  role="button"
                  aria-expanded="false"
                  aria-controls="navbarCollapseServices"
                >
                  <span>
                    Legal Services{" "}
                    <img src="/assets/img/svg/dropdown_veector.svg" alt="" />{" "}
                  </span>
                </a>
                <div
                  className="navbar-collapse-menu collapse"
                  id="navbarCollapseServices"
                >
                  <ul className="nav navbar-nav">
                    {services.map((service) => (
                      <li
                        onClick={() => {
                          changeViewDispalyHide();
                        }}
                        className="nav-item for-cursor"
                        key={service._id} >
                        <a
                          className="nav-link for-cursor"
                          data-serviceid={service._id}
                          onClick={() => {
                          const slug = createSlug(service.name);
                          serviceFun(slug);
                          }}
                        >
                          <span>{service.name}</span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </li>
              <li className="nav-item navbar-collapse">
                <Link className="nav-link" to="/pricing">
                  <span
                    onClick={() => {
                      changeViewDispalyHide();
                    }}
                  >
                    Subscription Plans
                  </span>
                </Link>
              </li>
              <li className="nav-item navbar-collapse">
                <Link className="nav-link" to="/blogs">
                  <span>Blog</span>
                </Link>
              </li>
              <li className="nav-item navbar-collapse">
                <Link className="nav-link" to="/meet-the-lawyer">
                  <span
                    onClick={() => {
                      changeViewDispalyHide();
                    }}
                  >
                    Meet the Lawyers
                  </span>
                </Link>
              </li>
              <li className="nav-item navbar-collapse">
                {(localStorage.getItem("token") === "" ||
                  localStorage.getItem("token") === null) &&
                  (localStorage.getItem("user_id") === "" ||
                    localStorage.getItem("user_id") === null) && (
                    <div
                      className="btn btn btn-sm btn-accent-1 ms-auto me-30 me-lg-0 order-2 order-lg-3 btn-legal-hub"
                      onClick={mylegalHub}
                    >
                      <img src="/assets/img/svg/account.svg" alt="" />
                      &nbsp; My Legal Hub
                    </div>
                  )}

                {localStorage.getItem("token") &&
                  localStorage.getItem("user_id") && (
                    <>
                      <li className="nav-item navbar-collapse">
                        <a
                          className="btn btn-sm btn-accent-1 ms-auto me-30 me-lg-0 order-2 order-lg-3 btn-legal-hub"
                          target="_self"
                          data-bs-toggle="collapse"
                          role="button"
                          aria-controls="mylegalhub"
                          href="#mylegalhub"
                          aria-expanded="true"
                        >
                          {" "}
                          <img src="/assets/img/svg/account.svg" alt="" />
                          &nbsp;{" "}
                          <span className="d-flex align-items-center">
                            My Legal Hub
                          </span>
                        </a>
                        <div
                          className="navbar-collapse-menu collapse"
                          id="mylegalhub"
                          style={{}}
                        >
                          <ul className="nav navbar-nav">
                            <li className="nav-item for-cursor">
                              <a className="nav-link for-cursor">
                                <span
                                  onClick={(e) => {
                                    e.preventDefault();
                                    navigate("/my-profile");
                                    changeViewDispalyHide();
                                  }}
                                  href="/my-profile"
                                >
                                  Profile
                                </span>
                              </a>
                            </li>
                            <li className="nav-item for-cursor">
                              <a className="nav-link for-cursor">
                                <span
                                  onClick={(e) => {
                                    e.preventDefault();
                                    logout();
                                  }}
                                  href="/signin"
                                >
                                  Logout
                                </span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </li>
                      {/* </div>
                      </div> */}
                    </>
                  )}
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/about-us">
                  <span
                    onClick={() => {
                      changeViewDispalyHide();
                    }}
                  >
                    {" "}
                    How We Work
                  </span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/contact-us">
                  <span
                    onClick={() => {
                      changeViewDispalyHide();
                    }}
                  >
                    Contact Us{" "}
                  </span>
                </Link>
              </li>
              <div className="d-flex justify-content-around">
                <li className="nav-item">
                  <a
                    className="nav-link for-cursor"
                    href="https://www.facebook.com/LawForMe1/"
                    target="_blank"
                  >
                    <img src="/assets/img/svg/fb.svg" alt="" />
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="https://www.linkedin.com/company/law-for-me/"
                    target="_blank"
                    className="nav-link for-cursor"
                  >
                    <img src="/assets/img/svg/linkdin.svg" alt="" />
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="https://twitter.com/law_for_me_"
                    target="_blank"
                    className="nav-link for-cursor"
                  >
                    <img src="/assets/img/svg/twit.svg" alt="" />
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="https://www.instagram.com/law_for_me_/"
                    target="_blank"
                    className="nav-link for-cursor"
                  >
                    <img src="/assets/img/svg/insta.svg" alt="" />
                  </a>
                </li>
              </div>
            </ul>
          </div>
        </div>
      </div>

      {/* Topbar*/}
      <div className="navbar navbar-topbar navbar-expand-xl navbar-dark navbar-absolute top-0 d-none d-xl-flex">
        <div className="container">
          {/* Socials*/}
          <ul className="nav nav-gap-sm navbar-nav nav-social ms-auto nav-no-opacity align-items-center">
            <li className="nav-item">
              <Link className="nav-link" to="/about-us">
                How We Work
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/contact-us">
                Contact Us
              </Link>
            </li>
            <li className="nav-item">
              <a
                className="nav-link for-cursor"
                href="https://www.facebook.com/LawForMe1/"
                target="_blank"
              >
                <img src="/assets/img/svg/fb.svg" alt="" />
              </a>
            </li>
            <li className="nav-item">
              <a
                href="https://www.linkedin.com/company/law-for-me/"
                target="_blank"
                className="nav-link for-cursor"
              >
                <img src="/assets/img/svg/linkdin.svg" alt="" />
              </a>
            </li>
            <li className="nav-item">
              <a
                href="https://twitter.com/law_for_me_"
                target="_blank"
                className="nav-link for-cursor"
              >
                <img src="/assets/img/svg/twit.svg" alt="" />
              </a>
            </li>
            <li className="nav-item">
              <a
                href="https://www.instagram.com/law_for_me_/"
                target="_blank"
                className="nav-link for-cursor"
              >
                <img src="/assets/img/svg/insta.svg" alt="" />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <Navbarjs />
    </>
  );
};

export default memo(Navbar);

import React, { Suspense, useRef } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

import Home from "./components/Pages/Home/Home";
import Header from "./components/Layouts/Header";
import Footer from "./components/Layouts/Footer";
import Loader from "./components/Common/Loader";
import ScrollToTop from "./components/ScrollToTop";
import NotFound from "./components/Pages/NotFound/NotFound";

// For Idle Logout
import Config from "./config";
import IdleLogout from "./components/Common/IdleLogout";
import LegalDocumentReview from "./components/Pages/LegalDocument/LegalDocumentReview";
const Signin = React.lazy(() => import("./components/Auth/Signin/Signin"));
const Signup = React.lazy(() => import("./components/Auth/Signup/Signup"));
const ActiveAccount = React.lazy(() =>
  import("./components/Auth/ActiveAccount/ActiveAccount")
);
const ForgetPassword = React.lazy(() =>
  import("./components/Auth/ForgetPassword/ForgetPassword")
);
const ResetPassword = React.lazy(() =>
  import("./components/Auth/ResetPassword/ResetPassword")
);

const Blog = React.lazy(() => import("./components/Pages/Blog/Blog"));
const BlogDetail = React.lazy(() =>
  import("./components/Pages/BlogDetail/BlogDetail")
);
const LegalDocumentSubCategory = React.lazy(() =>
  import("./components/Pages/LegalDocument/LegalDocumentSubCategory")
);
const MeetLawyer = React.lazy(() =>
  import("./components/Pages/MeetLawyer/MeetLawyer")
);
const SelectLawyer = React.lazy(() =>
  import("./components/Pages/SelectLawyer/SelectLawyer")
);
const Pricing = React.lazy(() => import("./components/Pages/Pricing/Pricing"));
const CheckoutSignup = React.lazy(() =>
  import("./components/Checkout/CheckoutSignup/CheckoutSignup")
);
const LegalService = React.lazy(() =>
  import("./components/Pages/LegalService/LegalService")
);
const CheckoutMultipleCart = React.lazy(() =>
  import("./components/Checkout/CheckoutMultipleCart/CheckoutMultipleCart")
);

const CheckoutSubscriptionPlanComplete = React.lazy(() =>
  import(
    "./components/Checkout/CheckoutSubscriptionPlanComplete/CheckoutSubscriptionPlanComplete"
  )
);

const CheckoutMultipleCartComplete = React.lazy(() =>
  import(
    "./components/Checkout/CheckoutMultipleCartComplete/CheckoutMultipleCartComplete"
  )
);

const CheckoutSubscriptionPlan = React.lazy(() =>
  import(
    "./components/Checkout/CheckoutSubscriptionPlan/CheckoutSubscriptionPlan"
  )
);

const CheckoutPayment = React.lazy(() =>
  import("./components/Checkout/CheckoutPayment/CheckoutPayment")
);
const TermsOfUse = React.lazy(() =>
  import("./components/Pages/TermsOfUse/TermsOfUse")
);

const About = React.lazy(() => import("./components/Pages/About/About"));
const ContactUs = React.lazy(() =>
  import("./components/Pages/ContactUs/ContactUs")
);

const PrivacyPolicy = React.lazy(() =>
  import("./components/Pages/PrivacyPolicy/PrivacyPolicy")
);

const BusinessOfUse = React.lazy(() =>
  import("./components/Pages/BusinessOfUse/BusinessOfUse")
);

const DocumentPayment = React.lazy(() =>
  import("./components/Checkout/CheckoutMultipleCart/DocumentPayment")
);
const DocumentSignup = React.lazy(() =>
  import("./components/Checkout/CheckoutMultipleCart/DocumentSignup")
);

const CookiePage = React.lazy(() =>
  import("./components/Pages/CookiePage/CookiePage")
);
const ConsultationDate = React.lazy(() =>
  import("./components/Consultation/ConsultationDate/ConsultationDate")
);

const CompleteDocumentPayment = React.lazy(() =>
  import("./components/Checkout/CheckoutMultipleCart/CompleteDocument")
);
const LegalDocumentTest = React.lazy(() =>
  import("./components/Pages/LegalDocument/LegalDocumentTest")
);

const LawyerProfile = React.lazy(() =>
  import("./components/Pages/LawyerProfile/LawyerProfile")
);

const RequestCallBack = React.lazy(() =>
  import("./components/Pages/RequestCallBack/RequestCallBack")
);

const MasterProfile = React.lazy(() =>
  import("./components/Pages/MasterProfile/index")
);

const DuePayment = React.lazy(() =>
  import("./components/Pages/DuePayment/DuePayment")
);

function App() {
  const tawkMessengerRef = useRef();

  const handleMinimize = () => {
    tawkMessengerRef.current.minimize();
  };

  // IdleLogout 15mins
  const handleLogout = () => {
    // Perform your logout action here
    console.log('User logged out');
    localStorage.clear()
  };
  return (
    <>
      
      <Router>
        <ScrollToTop />
        <IdleLogout timeout={600} onLogout={handleLogout} /> 
        <Suspense fallback={<Loader />}>
          <Routes>
            <Route
              exact={true}
              path="/signin"
              element={<Signin isShow="false" />}
            />
            <Route
              exact={true}
              path="/signup"
              element={<Signup isShow="false" />}
            />
            <Route
              exact={true}
              path="/activate-account"
              element={<ActiveAccount isShow="false" />}
            />
            <Route
              exact={true}
              path="/forgot-password"
              element={<ForgetPassword isShow="false" />}
            />
            <Route
              exact={true}
              path="/reset-password"
              element={<ResetPassword isShow="false" />}
            />
            <Route
              exact={true}
              path="/blogs"
              element={<Blog isShow="false" />}
            />
            <Route
              exact={true}
              path="/blogs/:artical_id"
              element={<BlogDetail isShow="false" />}
            />
            <Route
              exact={true}
              path="/blog-details/t/:id"
              element={<BlogDetail isShow="false" />}
            />
          </Routes>
        </Suspense>

        <body className="has-topbar">
          <Header />

          <Suspense fallback={<Loader />}>
            <Routes>
              <Route exact={true} path="/" element={<Home />} />
              <Route path="/meet-the-lawyer" element={<MeetLawyer />} />
              <Route
                exact={true}
                path="/legal-document-subcategory/lds/:id/:name"
                element={<LegalDocumentSubCategory />}
              />
              <Route
                exact={true}
                path="/legal-document/:sub_category_id"
                element={<LegalDocumentSubCategory />}
              />
              <Route path="/pricing" element={<Pricing />} />
              <Route path="/select-lawyer" element={<SelectLawyer />} />
              <Route
                exact={true}
                path="/checkout-signup"
                element={<CheckoutSignup />}
              />
              <Route
                exact={true}
                path="/checkout-payment"
                element={<CheckoutPayment />}
              />

              <Route path="/about-us" element={<About />} />
              <Route exact={true} path="/contact-us" element={<ContactUs />} />
              <Route
                exact={true}
                path="/contact-us/join-the-team"
                element={<ContactUs />}
              />
              <Route
                exact={true}
                path="/contact-us/bespoke-agreements"
                element={<ContactUs />}
              />

              <Route
                path="/lawyer-profile/lawyer/:id"
                element={<LawyerProfile />}
              />

              <Route
                path="/meet-the-lawyer/:lawyer_id"
                element={<LawyerProfile />}
              />
              <Route
                path="/legal-service/ls/:id"
                element={<LegalService />}
              />

              <Route
                path="/legal-service/:service_id"
                element={<LegalService />}
              />

              <Route
                path="/legal-document/t/:test_id"
                element={<LegalDocumentTest />}
              />

              <Route
                path="/legal-document/t/:test_id/review"
                element={<LegalDocumentReview />}
              />

              <Route
                exact={true}
                path="/checkout-subscription-plan"
                element={<CheckoutSubscriptionPlan />}
              />
              <Route
                exact={true}
                path="/checkout-subscription-plan-complete"
                element={<CheckoutSubscriptionPlanComplete />}
              />

              <Route
                exact={true}
                path="/checkout-multiple-cart-complete"
                element={<CheckoutMultipleCartComplete />}
              />
              <Route
                exact={true}
                path="/checkout-multiple-cart"
                element={<CheckoutMultipleCart />}
              />
              <Route
                exact={true}
                path="/terms-of-use"
                element={<TermsOfUse />}
              />
              <Route
                exact={true}
                path="/consultation-date/lawyer/:lawyer_id"
                element={<ConsultationDate />}
              />

              <Route
                exact={true}
                path="/document-signup"
                element={<DocumentSignup />}
              />

              <Route
                exact={true}
                path="/document-payment"
                element={<DocumentPayment />}
              />

              <Route
                exact={true}
                path="/document-complete"
                element={<CompleteDocumentPayment />}
              />
              <Route
                exact={true}
                path="/request-call-back"
                element={<RequestCallBack />}
              />

              <Route
                exact={true}
                path="/cookie-policy"
                element={<CookiePage />}
              />
              <Route
                exact={true}
                path="/privacy-policy"
                element={<PrivacyPolicy />}
              />
              <Route
                exact={true}
                path="/business-of-use"
                element={<BusinessOfUse />}
              />
              <Route
                exact={true}
                path="/due-payment"
                element={<DuePayment />}
              />
              <Route
                exact={true}
                path="/my-profile"
                element={<MasterProfile />}
              />
              <Route
                exact={true}
                path="/agreement"
                element={<MasterProfile />}
              />
              <Route
                exact={true}
                path="/order-history"
                element={<MasterProfile />}
              />
              <Route
                exact={true}
                path="/consultation"
                element={<MasterProfile />}
              />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Suspense>
          <Footer />
        </body>
      </Router>
      <div className="App">
        <TawkMessengerReact
          propertyId={Config.PROPERTY_ID}
          widgetId={Config.WIDGET_ID}
          ref={tawkMessengerRef}
        />
      </div>
    </>
  );
}

export default App;

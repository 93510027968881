import React from "react";

const NotFound = () => (
  <div style={{textAlign:'center', marginTop:'100px', marginBottom:'100px'}}>
    <h1>404 - Not Found</h1>
    <p>The page you are looking for does not exist.</p>
  </div>
);

export default NotFound;

import React, { memo } from "react";
import { useNavigate } from "react-router-dom";
import { createSlug } from "../Widgets/Slug/Slug";

const LegalServiceCom = ({ type, src, uniqueId }) => {
  const history = useNavigate();
  const RdirService = (uniqueid) => {
    history(`/legal-service/${uniqueid}`);
  };
  return (
    <>
      <div className="swiper-slide swiper-slide-duplicate swiper-slide-active industry-slider legal-service-slider">
        <div
          className="industry rounded-18 overflow-hidden"
          // style={{ height: "350px" }}
        >
          <div
            className="industry-slide-inner  rounded-18 overflow-hidden"
            // style={{ height: "350px" }}
          >
            <a
              className="industry-link stretched-link for-cursor"
              onClick={(e) => {
                e.preventDefault();
                const slug = createSlug(type);
                RdirService(slug);
              }}
            />
            <img
              src="https://dev.lawforme.co.uk/html/website/assets/img/svg/building1.svg"
              alt=""
              className="building-img for-cursor"
              onClick={() => {
                const slug = createSlug(type);
                RdirService(slug);
              }}
            />
            <div className="industry-image newhyphanimage-114 for-cursor">
              <img
                loading="lazy"
                src={src}
                alt=""
                onClick={() => {
                  const slug = createSlug(type);
                  RdirService(slug);
                }}
              />
            </div>
            <div className="industry-body legal_dvisory_box">
              <h4
                className="industry-title m-0 mb-5 text-dark for-cursor"
                onClick={(e) => {
                  const slug = createSlug(type);
                  RdirService(slug);
                }}
              >
                {type}
              </h4>
              <span className="legal_arrow for-cursor">
                <img
                  src="https://dev.lawforme.co.uk/html/website/assets/img/svg/arrow.svg"
                  alt=""
                  onClick={(e) => {
                    const slug = createSlug(type);
                    RdirService(slug);
                  }}
                />
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(LegalServiceCom);

import axios from "axios";

const app = {
  //UPLOAD_URL: "https://url.co/uploads/", // same
  //PUBLIC_DIR: "https://data.co/", // same
  // Path: "https://apidev.lawforme.co.uk/img/",
  // SITE_URL: "https://lawforme.co.uk", // should be same for all cases...
  // MapAPI: "AIzaSyAkSdoMo4Ts1YTGny5fHAaXtdPmmlKx0tY",

  // for dev
  // BASE_URL: "https://apidev.lawforme.co.uk/api", // lawforme.co.uk
  // stripeKey: "pk_test_Rf5wRg1EBJnZAGpxA1mSIzrZ00X5ooKwsZ",
  // PROPERTY_ID: "641c2c7531ebfa0fe7f4390a",
  // WIDGET_ID: "1gs70rbum",

  // for uat
  // BASE_URL: "https://apiuat.lawforme.co.uk/api", // lawforme.co.uk
  // stripeKey: "pk_test_Rf5wRg1EBJnZAGpxA1mSIzrZ00X5ooKwsZ",
  // PROPERTY_ID: "6423efca4247f20fefe89a51",
  // WIDGET_ID: "1gsm61bnf",

  // for Vercel UAT
  // BASE_URL: process.env.REACT_APP_API_BASE_URL,
  // stripeKey: process.env.REACT_APP_STRIPE_KEY,
  // PROPERTY_ID: process.env.REACT_APP_PROPERTY_ID,
  // WIDGET_ID: process.env.REACT_APP_WIDGET_ID,
  // Path: process.env.REACT_APP_API_IMAGE_PATH,
  // SITE_URL: process.env.REACT_APP_SITE_URL,
  // MapAPI: process.env.REACT_APP_GOOGLE_MAP_API_KEY,

  //for live
  BASE_URL: "https://api.lawforme.co.uk/api", // lawforme.co.uk
  stripeKey: "pk_live_xzSl6gVseWyHmFNsglBIKQ0b00ggm5g0WD",
  PROPERTY_ID: "6423efca4247f20fefe89a51",
  WIDGET_ID: "1gsm61bnf",
  Path: "https://api.lawforme.co.uk/img/",
  SITE_URL: "https://lawforme.co.uk/",
  MapAPI: "AIzaSyAkSdoMo4Ts1YTGny5fHAaXtdPmmlKx0tY",
};

app.dataType = "json";
app.contentType = {
  urlencoded: "appliction/x-www-form-urlencoded",
  json: "application/json",
  formData: "multipart/form-data;",
  no: false,
};
// var token = localStorage.getItem("token");
app.get = (url, run = function () { }, base = null) => {
  if (!base) url = app.BASE_URL + url;

  let token = localStorage.getItem("token");
  var config = {
    method: "get",
    url: `${url}`,
    headers: {
      "Content-Type": app.contentType[app.dataType],
      "cache-control": "no-cache",
      Authorization: "Bearer " + token,
    },
  };

  return new Promise((resolve, reject) => {
    axios(config)
      .then((res) => {
        resolve(res.data);
        run(null, res.data);
      })
      .catch((err) => {
        reject(err);
        run(true, err);
      });
  });
};

app.post = (url, data, run, base = null) => {
  var token = localStorage.getItem("token");
  if (!base) url = app.BASE_URL + url;
  var config = {
    method: "post",
    url: `${url}`,
    data: data,
    headers: {
      "Content-Type": app.contentType[app.dataType],
      Authorization: "Bearer " + token,
    },
  };

  return new Promise((resolve, reject) => {
    axios(config)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

app.paymentPost = (url, data, run, base = null) => {
  var token = localStorage.getItem("token");
  if (!base) url = app.BASE_URL + url;
  var config = {
    method: "post",
    url: `${url}`,
    data: data,
    headers: {
      "Content-Type": app.contentType[app.dataType],
      Authorization: "Bearer " + token,
    },
  };
  return new Promise((resolve, reject) => {
    axios(config)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

app.apipost = (url, data, run, base = null) => {
  var token = localStorage.getItem("token");
  if (!base) url = app.BASE_URL + url;
  var config = {
    method: "post",
    url: `${url}`,
    data: data,
    headers: {
      "Content-Type": app.contentType[app.dataType],
      Authorization: "Bearer " + token,
    },
  };

  return new Promise((resolve, reject) => {
    axios(config)
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

app.api = (url, type, data, base = null) => {
  var token = localStorage.getItem("token");
  if (!base) url = app.BASE_URL + url;
  var config = {
    method: type,
    url: `${url}`,
    data: data,
    headers: {
      "Content-Type": app.contentType[app.dataType],
      Authorization: "Bearer " + token,
    },
  };

  return new Promise((resolve, reject) => {
    axios(config)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

app.put = (url, data, run, base = null) => {
  var token = localStorage.getItem("token");
  if (!base) url = app.BASE_URL + url;
  var config = {
    method: "put",
    url: `${url}`,
    data: data,
    headers: {
      "Content-Type": app.contentType[app.dataType],
      Authorization: "Bearer " + token,
    },
  };

  return new Promise((resolve, reject) => {
    axios(config)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

app.delete = (url, data, run, base = null) => {
  var token = localStorage.getItem("token");
  if (!base) url = app.BASE_URL + url;
  var config = {
    method: "delete",
    url: `${url}`,
    data: data,
    headers: {
      "Content-Type": app.contentType[app.dataType],
      Authorization: "Bearer " + token,
    },
  };

  return new Promise((resolve, reject) => {
    axios(config)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

app.date = (d = null) => {
  var dt = null;
  if (!d) dt = new Date();
  else dt = new Date(d);

  var month = dt.getMonth() + 1;
  var day = dt.getDate();
  var year = dt.getFullYear();
  if (day < 10) day = `0${day}`;
  if (month < 10) month = `0${month}`;

  return year + "-" + month + "-" + day;
};

app.dateByFormat = (d = null) => {
  var dt = null;
  if (!d) dt = new Date();
  else dt = new Date(d);

  var month = dt.getMonth() + 1;
  var day = dt.getDate();
  var year = dt.getFullYear();
  if (day < 10) day = `0${day}`;
  if (month < 10) month = `0${month}`;

  return day + "-" + month + "-" + year;
};

app.toRemoveLoader = (timing = 1500) => {
  setTimeout(() => {
    document.getElementsByClassName("loader-main-wrap")[0].style.display =
      "none";
  }, timing);
};

app.myDate = (d = null) => {
  var dt = null;
  if (!d) dt = new Date();
  else dt = d;

  const dateArray = dt.split(" ");

  return dateArray[2] + " " + dateArray[1] + " " + dateArray[3];
};

app.datetime = (d = null) => {
  var dt = null;
  if (!d) dt = new Date();
  else dt = new Date(d);

  var month = dt.getMonth() + 1;
  var day = dt.getDate();
  var year = dt.getFullYear();
  if (day < 10) day = `0${day}`;
  if (month < 10) month = `0${month}`;

  var h = dt.getHours();
  var m = dt.getMinutes();
  var s = dt.getSeconds();

  return `${year}-${month}-${day} ${h}:${m}:${s}`;
};

app.mobileValidation = (number) => {
  var phoneno = /((\+44(\s\(0\)\s|\s0\s|\s)?)|0)7\d{3}(\s)?\d{6}/;
  if (number.match(phoneno)) {
    return 200;
  } else {
    return "Mobile number should be in Uk format +447911123456 .";
  }
};

var id = 1;

app.getBookingId = (d = null) => {
  var dt = null;
  if (!d) dt = new Date();
  else dt = new Date(d);

  var month = dt.getMonth() + 1;
  var day = dt.getDate();
  var year = dt.getFullYear();
  if (day < 10) day = `0${day}`;
  if (month < 10) month = `0${month}`;

  var h = dt.getHours();
  var m = dt.getMinutes();
  var s = dt.getSeconds();
  id++;
  var fullid = year + month + day + id;
  return fullid;
};

app.isAlphaNumeric = (str) => {
  let code, i, len;
  for (i = 0, len = str.length; i < len; i++) {
    code = str.charCodeAt(i);
    if (
      !(code > 47 && code < 58) && // numeric (0-9)
      !(code > 64 && code < 91) && // upper alpha (A-Z)
      !(code > 96 && code < 123)
    ) {
      // lower alpha (a-z)
      return false;
    }
  }
  return true;
};

app.showToast = (msg = "No message", level = "default", time = 6000) => {
  let toaster_message = document.getElementById("toaster_message");
  toaster_message.innerHTML = msg;
  let toaster = document.getElementById("toaster");
  toaster.className = "";
  toaster.classList.add(level);
  toaster.classList.add("visible");
  setTimeout(() => {
    toaster.classList.remove("visible");
  }, time);
};

app.showMessage = (msg = "No message", type = "info") => {
  return (
    <div className={"alert alert-" + type} style={{ fontSize: "16px" }}>
      {msg}
    </div>
  );
};

app.isLogged = () => {
  const localToken = localStorage.getItem("token");
  const localUserId = localStorage.getItem("user_id");
  const localEmail = localStorage.getItem("email");
  const localBillingName = localStorage.getItem("billingName");
  const localIsMember = localStorage.getItem("isMember");
  if (
    localToken &&
    localToken.trim() !== "" &&
    localUserId &&
    localUserId.trim() !== "" &&
    localEmail &&
    localEmail.trim() !== "" &&
    localBillingName &&
    localBillingName.trim() !== "" &&
    localIsMember &&
    localIsMember.trim() !== ""
  ) {
    return true;
  }
  return false;
};

app.ukTimeZone = () => {
  return new Date(
    new Date().toLocaleString("en-US", {
      timeZone: "Europe/London",
    })
  );
};

app.init = () => {
  const css =
    "#toaster{z-index: 1;align-items:center;border-radius:7px;min-width:350px;bottom:0;display:flex;min-height:50px;max-height:50px;justify-content:space-between;left:50%;padding:0 20px;position:fixed;transform:translateX(-50%) translateY(150%);transition:transform .35s ease;background-color:#323232}.visible{transform:translateX(-50%) translateY(-50%)!important;opacity:1!important}.success{background-color:#16a085!important}.danger{background-color:#c0392b!important}.warning{background-color:#f38211!important}#toaster span{color:#fff!important;font-size:13px;white-space:nowrap;letter-spacing:1.2px;font-family:Roboto,Helvetica,Arial,sans-serif,z-index: 1}";
  const toaster_style = document.createElement("style");
  toaster_style.innerHTML = css;
  document.head.appendChild(toaster_style);

  const toaster = document.createElement("div");
  const toaster_message = document.createElement("span");
  toaster_message.id = "toaster_message";
  toaster.id = "toaster";
  toaster.appendChild(toaster_message);
  document.body.appendChild(toaster);
};

app.init();
app.print = (json) => {
  console.log(json);
  return JSON.stringify(json);
};

// app.checkAuth = () => {
// if (localStorage.getItem("token")) {
//   return true;
// } else return false;
// };

global.app = app;

export default app;

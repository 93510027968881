import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripeDuePayment from "./StripeDuePayment.js";
import Config from "../../config.js";

// var srcCall = "sk_test_DLIUyAIQ6PfBKrLfyJsatIof00EbZ6Xc27";

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(Config.stripeKey);

export default function App() {
  return (
    <Elements stripe={stripePromise}>
      <StripeDuePayment />
    </Elements>
  );
}

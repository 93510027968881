import React, { useEffect, useRef, useState } from "react";
import Config from "../../config";

import { useNavigate } from "react-router-dom";
import { useStripe } from "@stripe/react-stripe-js";
// import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

const StripeDuePayment = () => {
  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  const stripe = useStripe();
  const navigate = useNavigate();
  // const elements = useElements();

  if (!stripe) {
    // alert("somthing going worng ..... 🙂");
  }
  const [data, setData] = useState([]);

  const checkDue = () => {
    Config.get(
      `/payment/check-sub-amount-due?userId=${localStorage.getItem("user_id")}`
    )
      .then((res) => {
        console.log(res);
        if (res.status == true) {
          setData(res.data);
        }
      })
      .catch((err) => {
        console.log(err, "checkDue");
      });
  };

  const duePayDelete = () => {
    Config.delete(`/payment/delete-sub-amount-due?_id=${data._id}`)
      .then((res) => {
        console.log(res);
        if (res.status == true) {
          window.scrollTo(0, 0);
          navigate("/my-profile");
        }
      })
      .catch((err) => {
        console.log(err, "duePayDelete");
      });
  };

  const duePay = (data) => {
    stripe.confirmCardPayment(data.client_secret).then(function (result) {
      if (result.error) {
        console.log("requires_action!");
        console.log(result.error);
        // Display error message in your UI.
        // The card was declined (i.e. insufficient funds, card has expired, etc)
      } else {
        duePayDelete();
      }
    });
  };

  useEffect(() => {
    checkDue();
  }, []);

  return (
    <>
      <div className="" style={{ height: "100vh" }}>
        <div className="mt-90">
          {/* <Button
            variant="primary"
            onClick={handleShow}
            style={{ display: "block", margin: "auto" }}
          >
            click
          </Button> */}

          <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Body className="modal_body">
              <div className="titleCloseBtn">
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    handleClose();
                    window.scrollTo(0, 0);
                    navigate("/");
                  }}
                >
                  X
                </button>
              </div>
              <div className="mt-20 text-center">
                <p style={{ fontWeight: "900" }}>
                  Looks like, Your Plan was expired, Please pay now and start
                  using our Platform.
                </p>
              </div>
            </Modal.Body>
            <div className="plan-btn">
              <button
                onClick={(e) => {
                  duePay(data);
                }}
              >
                Pay Now
              </button>
              <button
                onClick={(e) => {
                  e.preventDefault();
                  handleClose();
                  window.scrollTo(0, 0);
                  navigate("/");
                }}
              >
                Close
              </button>
            </div>
          </Modal>
        </div>
      </div>
    </>
  );
};

export default StripeDuePayment;

import React from "react";
import { Helmet } from "react-helmet";

const Navbarjs = () => (
  <Helmet>
    {/* <script src="assets/vendors/js/swiper-bundle.js" type="text/javascript" /> */}
    {/* <script src="assets/vendor/bootstrap/js/bootstrap.bundle.min.js" type="text/javascript" /> */}
    {/* <script src="assets/vendors/js/gist-embed.min.js" type="text/javascript" /> */}
    {/* <script src="assets/js/helpers.js" type="text/javascript" /> */}

    {/* <script
      src="assets/js/controllers/show-on-scroll.js"
      type="text/javascript"
    /> */}
    {/* <script src="assets/js/controllers/swiper.js" type="text/javascript" /> */}
    <script src="assets/js/controllers/navbar.js" type="text/javascript" />
  </Helmet>
);
export default Navbarjs;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Config from "../../config";
const IdleLogout = ({ timeout, onLogout }) => {
  const [remainingTime, setRemainingTime] = useState(timeout);
  const navigate = useNavigate();

  const resetTimer = () => {
    setRemainingTime(timeout);
  };

  useEffect(() => {
    if (Config.isLogged()) {
      const resetTimerOnInteraction = () => {
        resetTimer();
      };

      const startTimer = () => {
          const interval = setInterval(() => {
            // console.log("counting",remainingTime)
          if (remainingTime > 0) {
            setRemainingTime((prevTime) => prevTime - 1);
          } else {
            clearInterval(interval);
            onLogout(); // Perform logout action here
            navigate("/signin"); // Redirect to login page
          }
        }, 1000);

        return interval;
      };

      const interactionEvents = [
        "mousedown",
        "mousemove",
        "keydown",
        "touchstart",
      ];
      interactionEvents.forEach((event) => {
        document.addEventListener(event, resetTimerOnInteraction);
      });

      const interval = startTimer();

      return () => {
        clearInterval(interval);
        interactionEvents.forEach((event) => {
          document.removeEventListener(event, resetTimerOnInteraction);
        });
      };
    }
  }, [remainingTime, navigate, onLogout, timeout]);

  return null;
};

export default IdleLogout;

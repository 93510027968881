import React, { memo } from "react";

function ServiceLine({
  isActive,
  src,
  src1,
  serviceLineTitle,
  id,
  funOnClick,
  serviceLineTitle1,
}) {
  const handleClick = (e) => {
    e.stopPropagation();
    if (e.target.getAttribute("data-id")) {
      funOnClick(e.target.getAttribute("data-id"), id);
    } else {
      funOnClick(e.target.lastChild.getAttribute("data-id"), id);
    }
  };

  return (
    <div className="col-sm-4 col-md-4 col-lg-3 animated col-xs-6" style={{}}>
      {/* Service line*/}
      <div
        data-id={serviceLineTitle1}
        onClick={handleClick}
        className={
          "service-line lift position-relative  service-line-sm box-css " +
          isActive
        }
        id={"li" + id}
      >
        <div className="service-line-icon text-accent-1">
          <img
            data-id={serviceLineTitle1}
            src={src}
            alt=""
            className="white_icon"
          />
          <img
            data-id={serviceLineTitle1}
            src={src1}
            alt=""
            className="pink_icon"
          />
        </div>
        <div className="service-line-body ms-40">
          <h4 className="service-line-title m-0" data-id={serviceLineTitle1}>
            {serviceLineTitle}
          </h4>
        </div>
      </div>
    </div>
  );
}

export default memo(ServiceLine);

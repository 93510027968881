import React, { memo } from "react";
import { useNavigate } from "react-router-dom";

function JoinOurTeam() {
  const navigate = useNavigate();
  return (
    <div className="container join_team_div">
      <div
        className="text-white pt-10 row"
        style={{
          backgroundColor: "#2D2347",
          borderRadius: "15px",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <div className="col-md-6">
          <div className="service-case-body position-relative join_team">
            <h1 className="service-case-title mb-15 text-white join_team_text">
              Join Our Team
            </h1>
            <p className="service-case-text font-size-15 mb-30">
              Want to be paid more, have more freedom, more control and more
              time for your family? Want to develop your own practice and grow
              it to scale with our support? Are you a consultant solicitor that
              is struggling with business development and lead generation or a
              consultant lawyer that wants to grow faster and scale up with more
              support and admin help?
            </p>
            <a
              href="/contact-us"
              onClick={(e) => {
                e.preventDefault();
                navigate("/contact-us/join-the-team");
              }}
              className="btn btn btn-sm btn-accent-1 ms-auto me-30 me-lg-0 order-2 order-lg-3"
            >
              Join the Team
            </a>
          </div>
        </div>
        <div
          className="col-md-6 join_team_div_position"
          style={{ zIndex: 9, textAlign: "right" }}
        >
          {/* <img src="/assets/img/img4.png" alt="" style={{ width: "77%" }} /> */}
          <img src="/assets/img/svg/img4.svg" alt="" style={{ width: "77%" }} />
        </div>
        <div className="round_bg justify-content-start align-items-end">
          <img
            loading="lazy"
            // src="/assets/img/round.png"
            src="/assets/img/svg/round.svg"
            alt=""
            width={364}
            height={300}
          />
        </div>
      </div>
    </div>
  );
}

export default memo(JoinOurTeam);

import React from "react";
import StripeDuePayment from "../../../stripe/Stripeduepayment";

function DuePayment() {
  return (
    <div>
      <StripeDuePayment />
    </div>
  );
}

export default DuePayment;
